import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap"
import SocialMediaLinks from "./socialmedialinks"
import logo  from "../styles/photos/AqualityLol.png"

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false,
    }
    this.toggleBound = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      navOpen: !this.state.navOpen
    })
  }
  render() {


    const links = [
      {href: '/', text: 'HOME', color: "white"},
      { href: "/#about", text: "ABOUT", color: "white" },
      { href: "/services/", text: "SERVICES", color: "white" },
      { href: "/gallery/", text: "GALLERY", color: "white" },
      // { href: "/testimonials/", text: "TESTIMONIALS", color: "white" },
      { href: "/contact/", text: "CONTACT", color: "white" },
    ]

    const createNavItem = ({ href, text, color }) => (
      <NavItem>
        <NavLink
          href={href}
          style={{ color, fontSize: "1rem", fontFamily: "Spartan"}}
          id="navItems"
        >
          {text}
        </NavLink>
      </NavItem>
    )
    return (

      <Navbar style={{backgroundColor: this.props.backgroundColor}} expand="lg"  light={true} fixed={true}>
        <NavbarBrand
          href="/"
        >
          <span id="aqualityA">A</span><span id="restOfLogo">-QUALITY PAVING LTD</span>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleBound} />
        <Collapse isOpen={this.state.navOpen} navbar={true}>

        <NavbarText className="ml-auto mr-5" style={{ color: "white", fontSize: '1.05rem' }}>
          WCB Approved!
        </NavbarText>
        <Nav navbar className="ml-auto mr-5">
          {links.map(createNavItem)}
        </Nav>

        <div>
           <SocialMediaLinks />
        </div>
        <div className="pl-md-3">
          <NavbarText style={{ color: "white", fontSize: '.80rem' }} >
            Phone: (587) 968-5979
            <br />
            Alt Phone: (403) 540-1206
            <br />
            Email: info@a-qualitypaving.ca
            <br />
            Address: 6827 8th Ave NE
          </NavbarText>
        </div>
        </Collapse>
      </Navbar>

    )
  }
}

export default Header
