import React from "react"
import Header from "../components/header"
import {
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Col,
  Row,
  Button,
} from "reactstrap"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>A-Quality Paving Ltd paving and asphalt company in Calgary Contact us for a free estimate! | Contact</title>
          <link rel="canonical" href="localhost:8000" />
        </Helmet>
        <Header backgroundColor="SlateGrey" />
        <Container>
          <Row>
            <Col sm={{ size: 6, offset: 3 }} className="my-3">
              <h1>
                <span style={{ borderBottom: "2px solid #990000" }}>
                  Contact
                </span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 6, offset: 3 }} className="my-sm-3">
              <Form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <FormGroup>
                  <Label for="fullName">Full Name</Label>
                  <Input
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="John Doe"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@example.com"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="12345 example st "
                  />
                  <FormGroup>
                    <Label for="phone">Phone Number</Label>
                    <Input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="4035555555"
                      required
                    />
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Enter your message (Be sure to include the type of service you're inquiring about!)"
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="primary" type="submit" value="Submit">
                    Submit
                  </Button>{" "}
                  <Button color="secondary">Cancel</Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <p style={{ color: "black" }}>
                Phone: (587) 968-5979
                <br />
                Email: info@a-qualitypaving.ca
                <br />
                Address: 6827 8th Ave NE, Calgary AB, Canada
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Contact
