import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
class ServicesModal extends React.Component {
  modalObj

  constructor(props) {
    super(props)
    const services = [
      {
        id: "1",
        title: "Bobcat and Truck Services",
        info:
          "This includes: Grading, Excavating and hauling materials offsite, Delivering many types of aggregates and will spread it out for you!",
      },
      {
        id: "2",
        title: "Hot Mix Asphalt(HMA) and Resurfacing",
        info: "Residential Driveways, Acreages and commercial parking lots.",
      },
      {
        id: "3",
        title: "Crack Fill",
        info:
          "We fill cracks in the asphalt with a hot Polymed Bitumen sealant to prevent water going into the cracks.",
      },
      {
        id: "4",
        title: "Asphalt Patch and Repair",
        info:
          "Filling potholes or excavated areas in the asphalt pavement to control further deterioration.",
      },
      {
        id: "5",
        title: "Recycled Asphalt Aggregate",
        info:
          "This process bonds together when wetted and compacted, allowing for a semi-permanent driveway that remains in place and minimizes dust. A cheaper alternative to Hot Mix Asphalt",
      },
      {
        id: "6",
        title: "Seal Coating",
        info:
          "Spraying a protective layer of seal coat to waterproof your asphalt minimizing the rate at which water enters the asphalt.",
      },
      {
        id: "7",
        title: "Snow Removal",
        info: "We do snow removal during the winter time. Whether you need your driveway, commercial parking lot, sidewalk snow removed, we can remove it!"
      }
    ]
    const condition = this.props.cardId

    this.modalObj = services.find(findModal)

    function findModal(options) {
      return options.id === condition
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>{this.modalObj.title}</ModalHeader>
        <ModalBody>{this.modalObj.info}</ModalBody>
        <ModalFooter>
          <Button className="cursor" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ServicesModal
