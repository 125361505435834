import React from "react"
import { SocialIcon } from 'react-social-icons';

function SocialMediaLinks(props) {

  const socialMediaLinks = [
    {
      url: "https://www.facebook.com/Aqualitypavingltd",
      network: "facebook",
      fgColor: "#ffffff"
    },
    {
      url: "https://www.instagram.com/aqualitypaving/",
      network: "instagram",
      fgColor: "#ffffff"
    },
    {
      url: "https://www.linkedin.com/in/fred-simmonds-05138a1b0",
      network: "linkedin",
      fgColor: "#ffffff"
    },
  ]

  const createSocialMediaLinks = ({
    url,
    network,
    fgColor
  }) => (
    <SocialIcon
      style={{ height: 40, width: 40 }}
      url={url}
      network={network}
      fgColor={fgColor}

    />
  )
  return(
    <div id="socialMediaLinks">
      {socialMediaLinks.map(createSocialMediaLinks)}
    </div>
  );
}

export default SocialMediaLinks