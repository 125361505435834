import React from "react"
import { Card, CardImg, CardBody, CardTitle, Button } from "reactstrap"
import ServicesModal from "./servicesModal"

class ServicesCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      cardId: this.props.cardId
    }
    this.toggleBound = this.toggle.bind(this)
  }
  //To toggle open the modal
  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    return (
      <Card color="">
        <CardImg id="cardImage" src={this.props.photo} />
        <CardTitle className="text-center m-0" style={{ fontSize: "1.5rem" }}>
          {this.props.title}
        </CardTitle>
        <CardBody className="mx-auto">
          <Button outline color="info" onClick={this.toggleBound}>
            Learn More
          </Button>
        </CardBody>
        <ServicesModal
          cardId={this.state.cardId}
          isOpen={this.state.showModal}
          toggle={this.toggleBound}
        />
      </Card>
    )
  }
}
export default ServicesCard
