import React from "react"
import { Col, Row, Container } from "reactstrap"
import SocialMediaLinks from "./socialmedialinks"

export default Footer

function Footer() {
  return (
    <React.Fragment>
      <div style={{ backgroundColor: "SlateGrey", paddingTop: "10px", bottom: 0 }}>
        <Container fluid={true}>
          <Row >
            <Col>
              <span style={{color: 'white'}}>A-Quality Paving Ltd&#169; 2020 All Rights Reserved</span>
            </Col>
            <Col>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <a href="/" style={{color: "white", textDecoraction: 'none'}}>Home</a>
                </li>
                <li>
                  <a href="/services/" style={{color: "white", textDecoraction: 'none'}}>Services</a>
                </li>
                <li>
                  <a href="/gallery/" style={{color: "white", textDecoraction: 'none'}}>Gallery</a>
                </li>
                <li>
                  <a href="/contact/" style={{color: "white", textDecoraction: 'none'}}>Contact</a>
                </li>
              </ul>
            </Col>
            <Col>
              <span style={{color: 'white'}}>Follow us!</span>
              <SocialMediaLinks />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
