import React, { useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import QuoteModal from "../components/quoteModal"
import { Helmet } from "react-helmet"

function Gallery(props) {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const data = useStaticQuery(graphql`
    query MyQuery {
      allImageSharp(sort: { fields: fixed___originalName } ) {
        edges {
          node {
            id
            fluid(quality: 90) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              originalImg
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>A-Quality Paving Ltd paving and asphalt company in Calgary | Gallery</title>
        <link rel="canonical" href="localhost:8000" />
      </Helmet>
      <Header backgroundColor="SlateGrey" />
      <Container fluid={true}>
        <Row>
          <Col sm={true} className="mt-3">
            <h1>
              <span style={{borderBottom: "2px solid #990000"}}>Gallery</span>
            </h1>
          </Col>
          <Col className="align-self-center" sm={true}>
            <Button
              outline
              color="success"
              className="float-right mr-1"
              onClick={toggle}
            >
              Free Quote!
            </Button>
            <QuoteModal
              isOpen={modal}
              toggle={toggle}
            />
          </Col>
        </Row>
        <Row>
          {data.allImageSharp.edges.map(edge => (
            <Col sm={{ size: 4 }} className="my-3">
              <Img  fluid={edge.node.fluid} style={{ height: "400px"}} />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden={true}
      >
        <input type="hidden" name="form-name" value="contact" />

        <input id="name" type="text" name="name" required />
        <input type="email" name="email" required />

        <input id="address" type="tel" name="address" required />

        <input id="message" type="textarea" name="message" required />

        <input type="submit" value="submit" />
      </form>
    </React.Fragment>
  )
}

export default Gallery
