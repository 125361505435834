import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

class MessageBanner extends React.Component {

  render() {
      const data = graphql`
  query MyQuery {
      allImageSharp(sort: { fields: fixed___originalName } ) {
        edges {
          node {
            id
            fluid(quality: 90) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              originalImg
            }
          }
        }
      }
    }
  `

      return (
      <Img fixed={data.allImageSharp.edges.fluid} />
    )
  }
}

export default MessageBanner


//<span>Photo by <a href="https://unsplash.com/s/photos/snow?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
