import React from "react"
import { Container, Row, Col } from "reactstrap"

function Layout(props) {
  return (
    <Container fluid className="m-0">
      <Row>
        <Col className="p-0">{props.header}</Col>
      </Row>
      <Row>
        <Col />
        <Col>{props.content}</Col>
        <Col />
      </Row>
      <Row className="p-0">
        <Col className="p-0">{props.footer}</Col>
      </Row>
    </Container>
  )
}

export default Layout