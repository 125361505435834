import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"

class QuoteModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Free Quote!
        </ModalHeader>
        <Form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
        <ModalBody>
            <FormGroup>
              <Label for="fullName">Full Name</Label>
              <Input
                type="text"
                name="name"
                id="fullName"
                placeholder="John Doe"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                type="text"
                name="address"
                id="address"
                placeholder="unit 0000 Street SW City, Province"
                required
              />
            </FormGroup>
          <FormGroup>
            <Label for="phone">Phone Number</Label>
            <Input
              type="tel"
              name="phone"
              id="phone"
              placeholder="4035555555"
              required
            />
          </FormGroup>
            <FormGroup>
              <Label for="message">Message</Label>
              <Input
                type="textarea"
                name="message"
                id="message"
                placeholder="Enter your message"
              />
            </FormGroup>

        </ModalBody>
          <FormGroup>
          <ModalFooter>
            <Button color="primary" type="submit" value="Submit">
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
          </FormGroup>
        </Form>
      </Modal>
    )
  }
}

export default QuoteModal
