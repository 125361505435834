import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import Header from "../components/header"
import { Container, Row, Col, Button } from "reactstrap"
import QuoteModal from "../components/quoteModal"
import bobcat from "../styles/photos/smilingman.jpg"
import image2 from "../styles/photos/DSC00066.jpg"
import pem from "../styles/photos/pem.jpg"
import mickie from "../styles/photos/mikysmile.jpg"
import joanne from "../styles/photos/joanne.jpg"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import MessageBanner from "../components/messageBanner"
import ServicesCard from "../components/servicesCard"
import snowRemoval from "../styles/photos/jason-abdilla-anE0V1ljPUI-unsplash.jpg"

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }

    this.toggleBound = this.toggle.bind(this)
  }
  //To toggle open the modal
  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>A-Quality Paving Ltd paving and asphalt company in Calgary | Home</title>
          <link rel="canonical" href="localhost:8000" />
        </Helmet>

        <Container className="landingPageImage" fluid={true}>
          <Row>
            <Col className="p-0">
              <Header backgroundColor="transparent" />
            </Col>
          </Row>

          <Row>

            <Col style={{ height: "45rem" }}>
              <h1 className="ladingPageText">A-QUALITY</h1>
              <p className="landingPageServicesText ">
                <a
                  className="text-decoration-none text-reset"
                  href={/services/}
                >
                  Services
                </a>
                <svg
                  className="bi bi-arrow-right"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8z"
                  />
                </svg>
              </p>

              <Button
                outline
                color="light"
                className="float-right mr-1"
                onClick={this.toggleBound}
              >
                Free Quote!
              </Button>

            </Col>
            <Row>
              <Col  sm={true}>
                <ServicesCard photo={snowRemoval} title="Snow Removal now available! Call or email us about having your snow removed!" cardId="7" />
              </Col>
            </Row>
          </Row>
        </Container>
        <Container fluid={true} className="values">
          <Row>
            <Col>
              <h1 className="valueText ">Values</h1>
            </Col>
          </Row>
          <Row>
            <Col className="textAlign" sm={4}>
              <span className="dot">Quality</span>
            </Col>
            <Col className="textAlign" sm={4}>
              <span className="dot">Passion</span>
            </Col>
            <Col className="textAlign" sm={4}>
              <span className="dot">Honesty</span>
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          <Row>
            <Col sm={6} className="p-0">
              <img src={bobcat} alt="Bobcat" className="sidebysideimage" />
            </Col>
            <Col sm={6} className="p-0">
              <img
                src={image2}
                alt="Seal Coating"
                className="sidebysideimage"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="aboutusImage" id="about">
          <Row>
            <Col>
              <h1 className="textAlign mt-5">About us</h1>
            </Col>
          </Row>
          <Row sm={true}>
            <Container fluid={true}>
              <Col sm={true}>
                <h1 className="mt-5 textAlign">
                  <i>
                    {" "}
                    "A-Quality Paving LTD strives to provide our customers with
                    expertise, experience <br /> and quality workmanship in the
                    asphalt paving industry"
                  </i>
                  <p style={{ fontSize: "10px" }}>- Fred Simmonds Owner/CEO</p>
                </h1>
                <Row sm={true}>
                  <Col sm={true}>
                    <p
                      className="textAlign"
                      style={{
                        fontSize: "1.25rem",
                        height: "auto",
                      }}
                    >
                      My partner and I have 25 years combined experience, we
                      used to work for other asphalt paving companies, but
                      decided to open our own company because we know we have
                      the skill and experience to provide quality and
                      aesthetically pleasing asphalt work. We strive to become
                      the best asphalt paving company in Alberta.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Container>
          </Row>
        </Container>
        <Container fluid={true} className="ourCrew">
          <Row>
            <Col sm={12}>
              <h1 className="textAlign mt-5"> Our Crew</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <img src={joanne} alt="Pem" className="crewPhotos" />
              <p className="textAlign">Co-Owner Joanne Simmonds</p>
            </Col>
            <Col sm={4}>
              <img src={pem} alt="Pem" className="crewPhotos" />
              <p className="textAlign">Owner/CEO Fred Simmonds</p>
            </Col>
            <Col sm={4}>
              <img src={mickie} alt="Pem" className="crewPhotos" />
              <p className="textAlign">Co-Owner Michael "Mickie" Crosdale</p>
            </Col>
          </Row>
        </Container>
        <Footer />
        <QuoteModal isOpen={this.state.showModal} toggle={this.toggleBound} />
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          hidden={true}
        >
          <input type="hidden" name="form-name" value="contact" />

          <input id="name" type="text" name="name" required />
          <input type="email" name="email" required />

          <input id="address" type="tel" name="address" required />

          <input id="message" type="textarea" name="message" required />

          <input type="submit" value="submit" />
        </form>
      </React.Fragment>
    )
  }
}

export default Home
