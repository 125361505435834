import React from "react"
import Header from "../components/header"
import { Container, Row, Col, Button } from "reactstrap"

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
  }
  //TODO: add later when have more testimonials
  render() {
    return (
      <React.Fragment>
        <Header backgroundColor="black" />
        <Container fluid={true}>

        </Container>
      </React.Fragment>
    )
  }
}

export default Testimonials
