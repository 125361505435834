import React from "react"
import Header from "../components/header"
import { Button, Col, Container, Form, Row } from "reactstrap"
import Bobcat from "../styles/photos/Bobcat.jpg"
import asphaltDriveway from "../styles/photos/asphaltDriveway.jpg"
import crackFilling from "../styles/photos/crackFilling.jpg"
import haulDirt from "../styles/photos/haulDirt.jpg"
import recycledAsphalt from "../styles/photos/recycledAsphalt.jpg"
import SealCoat from "../styles/photos/SealCoat.jpg"
import Footer from "../components/footer"
import QuoteModal from "../components/quoteModal"
import ServicesCard from "../components/servicesCard"
import snowRemoval from "../styles/photos/jason-abdilla-anE0V1ljPUI-unsplash.jpg"
import { Helmet } from "react-helmet"

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }

    this.toggleBound = this.toggle.bind(this)
  }
  //To toggle open the modal
  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" name="description" content="Paving and asphalt services Hot Mix Asphalt, Recycled Asphalt, Bobcat and Truck Services, Snow removal, Crack Fill, Seal Coating" />
          <title>A-Quality Hot Mix Asphalt, Recycled Asphalt, Bobcat and Truck Services, Snow removal, Crack Fill, Seal Coating | Services</title>
          <link rel="canonical" href="localhost:8000" />
        </Helmet>
        <Header backgroundColor="SlateGrey" />
        <Container fluid={true}>
          <Row>
            <Col sm={true} className="mt-3">
              <h1>
                <span style={{borderBottom: "2px solid #990000"}}>Services</span>
              </h1>
            </Col>
            <Col className="align-self-center" sm={true}>
              <Button
                outline
                color="success"
                className="float-right mr-1"
                onClick={this.toggleBound}
              >
                Free Quote!
              </Button>
              <QuoteModal
                isOpen={this.state.showModal}
                toggle={this.toggleBound}
              />
            </Col>
          </Row>
          <Row>
            <Col  sm={true}>
              <ServicesCard photo={snowRemoval} title="Snow Removal now available! Call or email us about having your snow removed!" cardId="7" />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={true}>
              <ServicesCard photo={Bobcat} title="Bobcat and Truck Services" cardId="1" />
            </Col>
            <Col sm={true}>
              <ServicesCard
                photo={asphaltDriveway}
                title="Hot Mix Asphalt(HMA) and Resurfacing"
                cardId="2"
              />
            </Col>
            <Col sm={true}>
              <ServicesCard
                photo={crackFilling}
                title="Crack Fill"
                cardId="3"
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={true}>
              <ServicesCard photo={haulDirt} title="Asphalt Patch and Repair" cardId="4" />
            </Col>
            <Col sm={true}>
              <ServicesCard
                photo={recycledAsphalt}
                title="Recycled Asphalt Aggregate"
                cardId="5"
              />
            </Col>
            <Col sm={true}>
              <ServicesCard photo={SealCoat} title="Seal Coating" cardId="6" />
            </Col>
          </Row>
        </Container>
        <Footer />
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          hidden={true}
        >
          <input type="hidden" name="form-name" value="contact" />

          <input id="name" type="text" name="name" required />
          <input type="email" name="email" required />

          <input id="address" type="tel" name="address" required />

          <input id="message" type="textarea" name="message" required />

          <input type="submit" value="submit" />
        </form>
      </React.Fragment>
    )
  }
}
export default Services
